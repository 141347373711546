import './component.css';
import logo from "../sigmashine.svg";

function Home(props) {
    return (
        <div className="Home">
            <h1 className="number-page">
                <span className="number">{props.number}</span>
                <span className="page-name">{props.name}</span>
            </h1>
            <img src={logo} className='logo' alt="logo" />
            <div>
                <h2 className="first">
                    <span><span className="initial">W</span>elcome To </span><br/>
                    <span>SigmaShine</span>
                </h2>
                <h2 className="second">
                    <span>Portfolio Site</span><br/>
                    <span>of A</span><br/>
                    <span>Full-Stack</span><br/>
                    <span>Web Developer</span><br/>
                    <span>And</span><br/>
                    <span>Designer</span>
                </h2>
            </div>
        </div>
    );
};

export default Home;