import './component.css';
import logoBlack from "../sigmashine.svg";
import fire from "../firebase";
import "firebase/firestore";
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

function Project(props) {
    const [pro,setPro] = useState([]);
    const db = fire.firestore();
    
    useEffect(() => {
        db.collection('Projects').orderBy('time','desc').onSnapshot(snap => {
            setPro(snap.docs.map(doc => ({
                id : doc.id,
                projectData : doc.data(),
            })))
        })
    },[db])
    return (
        <div className="Projects">
            <h1 className="number-page">
                <span className="number">{props.number}</span>
                <span className="page-name">{props.name}</span>
            </h1>
            <Link to='/profile' exact >
                <img src={logoBlack} alt='logo' className='logo-black' />
            </Link>
            <div className="project">
               {pro.map(i=>(
                    <div className="project-cards" key={i.id} style={{background : `url(${i.projectData.imageURL})`,backgroundPosition: "center", backgroundSize: "cover"}}>
                        <h3 className="category">{i.projectData.category}</h3>
                        <h4 className="site-name">Name: {i.projectData.project}</h4>
                        <h4 className="site-id">ID: {i.projectData.projectId}</h4>
                        <p className="description">{i.projectData.description}</p>
                        <Link to={{pathname : `${i.projectData.projectURL}`}} target="_blank" className="visit">Visit</Link>
                    </div>
               ))}
            </div>
        </div>
    );
};

export default Project;