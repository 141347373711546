import "./component.css";
import logoBlack from "../sigmashine.svg";
import firebase from "firebase/app";
import fire from "../firebase";
import "firebase/firestore";
import "firebase/auth";
import { useEffect, useState } from "react";

export default function Profile(props) {
    const del = <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 24 24" width="20px" fill="red"><path d="M0 0h24v24H0z" fill="none"/><path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"/></svg>;
    const db = fire.firestore();
    const auth = fire.auth();

    const [user,setUser] = useState('');
    const [email,setEmail] = useState('');
    const [pass,setPass] = useState('');
    const [comt,setComt] = useState([]);
    const [project,setProject] = useState('');
    const [category,setCategory] = useState('');
    const [pId,setPId] = useState('');
    const [description,setDescription] = useState('');
    const [iURL,setIURL] = useState('');
    const [pURL,setPURL] = useState('');

    useEffect(()=>{
        db.collection('comments').orderBy('time','desc').onSnapshot(snap => {
            setComt(snap.docs.map(doc=>({
                id : doc.id,
                comments : doc.data(),
            })))
        })
    },[db]);

    const addProject = (e) => {
        e.preventDefault();
        db.collection('Projects').add({
            project : project,
            category : category,
            projectId : pId,
            description : description,
            imageURL : iURL,
            projectURL : pURL,
            time : firebase.firestore.FieldValue.serverTimestamp(),
        });
        setProject('');
        setCategory('');
        setPId('');
        setDescription('');
        setIURL('');
        setPURL('');
        window.alert('New Project Added :)');
    }

    const handleLogin = (e) => {
        e.preventDefault();
        auth.signInWithEmailAndPassword(email,pass)
            .then(()=>{
                setEmail('');
                setPass('');
            })
            .catch((err) =>{
                const code = err.code;
                window.alert(code)
            })
    }

    auth.onAuthStateChanged((user) => {
        if (user) {
            setUser(user);
        } else {
            setUser('');
            auth.signOut();
        }
    })

    return (
        <div className="Profile">
            <h1 className="number-page">
                <span className="number">{props.number}</span>
                <span className="page-name">{props.name}</span>
            </h1>
            <img src={logoBlack} alt='logo' className='logo-black' />
            {
                (user)
                    ? (<div className="profile">
                            <form className="upload-project">
                                <input className="project-form" type="text" placeholder="Project Name" required
                                    onChange={(e)=> setProject(e.target.value)} value={project} />
                                <input className="project-form" type="text" placeholder="Category" required
                                    onChange={(e)=> setCategory(e.target.value)} value={category} />
                                <input className="project-form" type="text" placeholder="Project ID" required
                                    onChange={(e)=> setPId(e.target.value)} value={pId} />
                                <textarea className="project-form-textarea" placeholder="Description" required
                                    onChange={(e)=> setDescription(e.target.value)} value={description} />
                                <input className="project-form" type="url" placeholder="Image URL" required
                                    onChange={(e)=> setIURL(e.target.value)} value={iURL} />
                                <input className="project-form" type="url" placeholder="Project URL" required
                                    onChange={(e)=> setPURL(e.target.value)} value={pURL} />
                                <button className="add" onClick={addProject}>ADD TO PROJECTS</button>
                            </form>
                            <button className="logout" onClick={(e)=>{e.preventDefault();auth.signOut();}}>LOGOUT</button>
                            <div className="feeds">
                                {comt.map((i)=>(
                                    <div className="feed" key={i.id}>
                                        <h4 className="sender-name"><button style={{outline : "none", border : "none", background : "transparent", cursor : 'pointer'}} 
                                            onClick={(e)=>{e.preventDefault();db.collection('comments').doc(i.id).delete();}}>
                                                {del}
                                            </button> {i.comments.name}
                                        </h4>
                                        <h4 className="sender-name">{i.comments.email}</h4>
                                        <p>{i.comments.comment}</p>
                                    </div>
                                ))}
                                
                            </div>
                        </div>)
                    : (<form className="login">
                            <input type="email" className="log" placeholder="Email"
                                onChange={(e)=> setEmail(e.target.value)} value={email} />
                            <input type="password" className="log" placeholder="Password"
                                onChange={(e)=> setPass(e.target.value)} value={pass} />
                            <button className="signIn" onClick={handleLogin} >LOGIN</button>
                        </form>)
            }
        </div>
    );
}