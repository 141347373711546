import firebase from 'firebase/app';
import "firebase/analytics";

const fire = firebase.initializeApp({
    apiKey: "AIzaSyB0n93GQUNVsIB8KArQHmGDyNmxpIUQJFI",
    authDomain: "sigma-shine.firebaseapp.com",
    projectId: "sigma-shine",
    storageBucket: "sigma-shine.appspot.com",
    messagingSenderId: "108326491267",
    appId: "1:108326491267:web:66252d20806377872bf7b6",
    measurementId: "G-JNEG796K2W"
});
firebase.analytics();

export default fire;