import './component.css';
import logoBlack from "../sigmashine.svg";
import fire from "../firebase";
import firebase from "firebase/app";
import "firebase/firestore";
import { useState } from 'react';

function Contact(props) {
    const db = fire.firestore();
    const [name,setName] = useState('');
    const [email,setEmail] = useState('');
    const [comment,setComment] = useState('');

    const cLogo = {
        phone : <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#fff"><path d="M0 0h24v24H0z" fill="none"/><path d="M20.01 15.38c-1.23 0-2.42-.2-3.53-.56-.35-.12-.74-.03-1.01.24l-1.57 1.97c-2.83-1.35-5.48-3.9-6.89-6.83l1.95-1.66c.27-.28.35-.67.24-1.02-.37-1.11-.56-2.3-.56-3.53 0-.54-.45-.99-.99-.99H4.19C3.65 3 3 3.24 3 3.99 3 13.28 10.73 21 20.01 21c.71 0 .99-.63.99-1.18v-3.45c0-.54-.45-.99-.99-.99z"/></svg>,
        email : <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#fff"><path d="M0 0h24v24H0z" fill="none"/><path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H4V8l8 5 8-5v10zm-8-7L4 6h16l-8 5z"/></svg>
    }

    const send = (e) => {
        e.preventDefault();
        db.collection('comments').add({
            name : name,
            email : email,
            comment : comment,
            time : firebase.firestore.FieldValue.serverTimestamp(),
        });
        setName('');
        setEmail('');
        setComment('');
        window.alert('Submitted :)');
    }

    return (
        <div className="Contact">
            <h1 className="number-page">
                <span className="number">{props.number}</span>
                <span className="page-name">{props.name}</span>
            </h1>
            <img src={logoBlack} alt='logo' className='logo-black' />
            <div className="contact">
                <div className="info">
                    <h2>SigmaShine</h2>
                    <p>{cLogo.phone} <a href="tel:+916370787670">+91 637 078 7670</a></p>
                    <p>{cLogo.email} <a href="mailto:sameer@sigmashine.com">sameer@sigmashine.com</a></p>
                </div>
                <form className="contact-form">
                    <input type="text" className="detail" placeholder="Name" required
                        onChange={(e)=> setName(e.target.value)} value={name} />
                    <input type="email" className="detail" placeholder="Email" required
                        onChange={(e)=> setEmail(e.target.value)} value={email} />
                    <textarea className="textarea" placeholder="Tell me something about your project. I love to work with start-ups, businesses, organizations, individuals." required
                        onChange={(e)=> setComment(e.target.value)} value={comment} />
                    <button className="send" onClick={send}>SEND</button>
                </form>
            </div>
            
        </div>
    );
};

export default Contact;